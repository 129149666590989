import React from "react";
import AppBody from "../../components/AppBody";

const PrivacyPolicy = () => {
  const appName = "Chants d'Espérance"
  const privacyBody = (
    <div className="pt-5">
      <h1>Privacy Policy</h1>
      <p>
        This Privacy Policy governs the manner in which our mobile application, {appName},
        collects, uses, maintains and discloses information collected from users
        of the app.
      </p>
      <h4>Information Collection and Use</h4>

      <p>
        Our mobile application,{appName}, does not collect any personal information or user
        content from its users. We do not require users to create an account or
        provide any personal information to use our app. Our app does not access
        any information stored on the user's device.
      </p>

      <h3>Third-Party Services</h3>

      <p>
        Our app does not integrate with any third-party services or collect any
        information from third-party services.
      </p>

      <h3>Security</h3>

      <p>
        Our app takes the security of user information very seriously. We use
        industry-standard measures to protect the information that is collected
        from users. However, due to the nature of the internet and mobile
        devices, we cannot guarantee that information will be absolutely secure.
      </p>

      <h3>Changes to This Privacy Policy</h3>

      <p>
        We reserve the right to update this Privacy Policy at any time. Users
        will be notified of any changes to this Privacy Policy through the app.
      </p>

      <h3>Contact Us</h3>
      <p>
        If you have any questions about this Privacy Policy or the practices of
        our mobile application, {appName}, please contact us at info@judesaintil.com.
      </p>
    </div>
  );

  return AppBody(privacyBody);
};

export default PrivacyPolicy;
