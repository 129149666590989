import React from 'react';

import AppBody from '../../components/AppBody'

const Home = () => {
    const homeHtml =  (
        <div className='container h-100 justify-content-center d-flex align-items-center'>
            <div>
                <div className="text-center align-items-center nanum-brush-font">
                    <span style={{fontSize: "100px"}}>Under Construction</span>
                </div>
                <div className="text-center nanum-brush-font">
                    <span style={{fontSize: "50px"}} >Comming soon...</span>
                </div>
            </div>
        </div>
    )

    return AppBody(homeHtml);
}

export default Home