import React from 'react';
import Header from '../Header';
import { Container } from 'react-bootstrap';
import Footer from '../Footer';

const AppBody = (children: any) => {
    return (
        <div className="h-100">
            {/* <Header /> */}
            <Container className="h-100">
                {children}
            </Container>
            {/* <Footer /> */}
        </div>
        )
}

export default AppBody;